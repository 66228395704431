import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import HomeView from './home-view'
import {
  useOrderMethod,
  useThemeConfig,
} from '../../hook'

function HomeController() {
  // prepare hook
  const { i18n, t } = useTranslation()
  const { getContentGroup } = useThemeConfig()
  const { getSystemSetting } = useSystemSettings()
  const {
    selectableOrderMethod,
  } = useOrderMethod()

  // internal states
  const [pageReady] = useState(true)

  // local variable
  const seoTitle = t('screens.home.seo.title')
  const currentLocale = i18n.language

  const {
    code: homePageContentGroupCode,
    template: homePageContentGroupTemplate,
  } = useMemo(() => (
    getContentGroup('config.pages.homePage')
  ), [getContentGroup])

  const viewProps = {
    currentLocale,
    homePageContentGroupCode,
    homePageContentGroupTemplate,
    pageReady,
    selectableOrderMethod,
    seoTitle,
    // for structured data
    siteName: getSystemSetting('frontend.site_name'),
    siteUrl: getSystemSetting('frontend.site_url'),
  }

  return (
    <HomeView {...viewProps} />
  )
}

export default HomeController
