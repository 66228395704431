/* eslint-disable no-unused-expressions */
/* eslint-disable no-undef */
import React from 'react'
import { useTheme } from 'react-jss'
import { combineClassNames } from '../../helpers'
import {
  Layout, Loading, ContentGroup, SEO, OrderMethod,
} from '../../ui'
import useStyles from './home-style'

function HomeView({
  currentLocale,
  // home page content group code
  homePageContentGroupCode,
  homePageContentGroupTemplate,
  // the page will be ready after loading content group data is end
  pageReady,
  selectableOrderMethod,
  seoTitle,
  siteName,
  siteUrl,
}) {
  const { container } = useStyles()
  const theme = useTheme()
  const extraScripts = _.get(theme, 'config.pages.homePage.extraScripts', [])
  return (
    <Layout>
      {/* passing page specific data for SEO */}
      <SEO
        title={seoTitle}
        linkedData={[
          {
            '@context': 'http://schema.org',
            '@type': 'WebSite',
            name: siteName,
            url: siteUrl,
            potentialAction: {
              '@type': 'SearchAction',
              target: `${siteUrl}/${currentLocale}/products?q={search_term_string}`,
              'query-input': 'required name=search_term_string',
            },
          },
          {
            '@context': 'http://schema.org',
            '@type': 'Organization',
            url: siteUrl,
            logo: theme.config.logoImageDesktop,
          },
        ]}
      />
      {
        pageReady
          ? (
            <div className={combineClassNames([container, 'home-container'])}>
              {
                !_.isEmpty(selectableOrderMethod) && (
                  <OrderMethod />
                )
              }
              <ContentGroup
                code={homePageContentGroupCode}
                template={homePageContentGroupTemplate}
              />
            </div>
          )
          : (
            <Loading />
          )
      }
      {!_.isEmpty(extraScripts)
        && _.map(extraScripts, (script, idx) => <script key={idx} type={script.type}>{`${script.code}`}</script>)}
    </Layout>
  )
}

export default HomeView
